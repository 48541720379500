


import axios from 'axios';

const url = process.env.VUE_APP_API_URL; // Usa a URL da variável de ambiente

export const http = axios.create({
     baseURL: url,
   });
   
   http.defaults.timeout = 90000000;
   
   http.interceptors.response.use(
     function (response) {
       return response;
     },
     function (error) {
       return Promise.reject(error);
     }
   );
   
   export default {
     url,
   };

//const url = 'http://10.10.1.5:8091/'
//npm install dotenv@8.6.0

//const url = 'https://maestrix.grupoparque.com.br:8443/api-dashboard/'
//const url_dash = 'https://api.gpdf.com.br/apisocio/api/'
