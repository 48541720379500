<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <div
          class="grey--text font-weight" style="font-size:14px"
          v-text="title"
        />

        <h3  v-if="valuex === false" class="display-2 font-weight text--primary">
         {{ value }} <small>  {{smallValue}}</small>
        </h3>
          
          <div v-if="valuex === true" style="width: 200px; height: 40px;">
            <content-loader
              primaryColor="#d4ddf2"
              secondaryColor="#ffffff"
              :speed=1
              :height="80"
            ></content-loader>
          </div>
        
      </div>
    </template>
    
    <v-col
      cols="12"
      class="px-0"
    >
      <v-divider />
   

    <v-icon
      :color="subIconColor"
      size="18"
      class="ml-2 mr-1"
    >
      {{ subIcon }}
    </v-icon>
    
    <span style="font-size:12px"
      :class="subTextColor"
      class="font-weight"
      v-text="subText"
    />
    <v-divider />
    <v-icon
      :color="subIconColor"
      size="18"
      class="ml-2 mr-1"
    >
      {{ subIconE }}
    </v-icon>
    
    <span style="font-size:12px"
      :class="subTextColorE"
      class="font-weight"
      v-text="subTextE"
    />
  </v-col>
  


    <v-divider  />

      <br/><br/>

      <v-expansion-panels accordion class="no-elevation" elevation="1">
        <v-expansion-panel>
          <v-expansion-panel-header>Mais Informações</v-expansion-panel-header>
          <v-expansion-panel-content>
            
            <span v-if="subTextAdd !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd" /><br/>
            <span v-if="subTextAddV !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAddV" />
            <v-divider  />
            <span v-if="subTextAdd1 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd1" /><br/>
            <span v-if="subTextAdd1V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd1V" />
            <v-divider v-if="subTextAdd1V !== undefined"  />
            <span v-if="subTextAdd2E !== undefined"  >
             <br>
             <br>
            </span>

            <span v-if="subTextAdd2 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd2" /><br/>
            <span v-if="subTextAdd2V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd2V" />
            <v-divider v-if="subTextAdd2V !== undefined" ></v-divider>

        
            <span v-if="subTextAdd3E !== undefined"  >
             <br>
             <br>
            </span>

            <span v-if="subTextAdd3 !== undefined"  
            style="font-size: 16px;height: 100px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd3" /><br/>
            <span v-if="subTextAdd3V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd3V" />
            <v-divider v-if="subTextAdd3V !== undefined"  />

            <span v-if="subTextAdd4E !== undefined"  >
             <br>
             <br>
            </span>

            <span v-if="subTextAdd4 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd4" /><br/>
            <span v-if="subTextAdd4V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd4V" />

            <v-divider v-if="subTextAdd4V !== undefined"  />

            <span v-if="subTextAdd5 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd5" /><br/>
            <span v-if="subTextAdd5V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd5V" />

            <v-divider v-if="subTextAdd5V !== undefined"  />

            <span v-if="subTextAdd6 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd6" /><br/>
            <span v-if="subTextAdd6V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd6V" />

            <v-divider v-if="subTextAdd7V !== undefined"  />

            <span v-if="subTextAdd7 !== undefined"  
            style="font-size: 16px;"  
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd7" /><br/>
            
            <span v-if="subTextAdd7V !== undefined"  
            style="font-size: 18px; font-weight: bold;"
            :class="subTextColor" 
            class="font-weight" 
            v-text="subTextAdd7V" />

            <v-divider v-if="subTextAdd7V !== undefined"  />
            <br/>
   
            <v-col  
              cols="12"
              class="px-0 d-flex justify-center"
              >
            

            <v-icon @click="downloadFunction" :color="subIconColor" size="48" style="height: 100%;">
              mdi-cloud-download
            </v-icon>


            </v-col>


          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

            
  </base-material-card>
</template>

<script>
  import Card from './Card'
  import { ContentLoader } from "vue-content-loader"


  export default {
    name: 'MaterialStatsCardDetail',
    components: { ContentLoader },

    inheritAttrs: false,

    props: {
      ...Card.props,
      downloadFunction: Function, 
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subIconE: {
        type: String,
        default: undefined,
      },
      subIconColorE: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      subTextE: {
        type: String,
        default: undefined,
      },

      subTextAdd: {
        type: String,
        default: "",
      },
      subTextAdd1: {
        type: String,
        default: "",
      },
      subTextAdd2: {
        type: String,
        default: "",
      },
      subTextAdd3: {
        type: String,
        default: "",
      },
      subTextAdd4: {
        type: String,
        default: "",
      },
      subTextAdd5: {
        type: String,
        default: "",
      },
      subTextAdd6: {
        type: String,
        default: "",
      },
      subTextAdd7: {
        type: String,
        default: "",
      },

      subTextAddV: {
        type: String,
        default: "0",
      },
      subTextAdd1V: {
        type: String,
        default: "0",
      },
      subTextAdd2V: {
        type: String,
        default: "0",
      },
      subTextAdd3V: {
        type: String,
        default: "0",
      },
      subTextAdd4V: {
        type: String,
        default: undefined,
      },
      subTextAdd5V: {
        type: String,
        default: undefined,
      },
      subTextAdd6V: {
        type: String,
        default: undefined,
      },
      subTextAdd7V: {
        type: String,
        default: undefined,
      },
      subTextAdd2E: {
        type: String,
        default: undefined,
      },
      subTextAdd3E: {
        type: String,
        default: undefined,
      },
      subTextAdd4E: {
        type: String,
        default: undefined,
      },

      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      valuex: {
        type: Boolean,
        default: undefined,
      },
     
      smallValue: {
        type: String,
        default: undefined,
      },
    },

    methods: {
      calculateHeight() {
      // Suponha que você queira 30% da altura do elemento pai
      const parentHeight = 120; // Substitua isso pela altura real do elemento pai
      const percentage = 30;
      return (parentHeight * percentage) / 100;
      }
    },
  }
</script>

<style scoped>
  .no-elevation {
    box-shadow: none !important;
  }
</style>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

    .custom-sub-text 
      font-size: 12px
    

  .v-card__actions
    flex: 1 0 100%
</style>
