import { http } from './config'; // Importa a instância configurada do axios

const API_URL = 'api/auth/'; // Parte da URL base específica para autenticação

class AuthService {
  login(user) {
    return http
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password,
      })
      .then(response => {
        if (response.data.checktoken === true) {
          if (response.data.accessToken) {
            sessionStorage.setItem('user', JSON.stringify(response.data));
          }
        } else {
          return '2';
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem('user');
  }

  register(user) {
    return http.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      empresa: 'Previda'
    });
  }
}

export default new AuthService();
