// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import JsonExcel from 'vue-json-excel'
import VueMask from 'v-mask'
import ApexCharts from 'apexcharts'

Vue.config.productionTip = false
Vue.use(VueMask);

Vue.component('downloadExcel', JsonExcel)

import pt from 'vuetify/lib/locale/pt';

router.beforeEach((to, from, next) => {
 
 
  if(to.path != '/login' && to.path != '/cadastro'){
    const user = JSON.parse(sessionStorage.getItem('user'))

    if(user == null){
      router.push('login');
    }

    if(to.path == '/contrato'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_CONTRATO')){
        router.push('restrito');
      }
    }

   

    if(to.path == '/cobranca'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_COBRANCA')){
        router.push('restrito');
      }
    }

    if(to.path == '/negativados'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_NEGATIVADO')){
        router.push('restrito');
      }
    }
   
    if(to.path == '/remessa'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_REMESSA_NEGATIVADO')){
        router.push('restrito');
      }
    }

    if(to.path == '/obito'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_OBITO')){
        router.push('restrito');
      }
    }

    if(to.path == '/usuario'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_USER')){
        router.push('restrito');
      }
    }

    if(to.path == '/historico'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_LOG')){
        router.push('restrito');
      }
    }

    if(to.path == '/faturamento'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_FATURAMENTO')){
        router.push('restrito');
      }
    }


    if(to.path == '/sepultamento'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_SEPULTAMENTO')){
        router.push('restrito');
      }
    }

    if(to.path == '/cancelamento'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_CANCELAMENTO')){
        router.push('restrito');
      }
    }

    if(to.path == '/venda'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_VENDA')){
        router.push('restrito');
      }
    }

     if(to.path == '/acordos'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_RENEGOCIACAO')){
        router.push('restrito');
      }
    }

    if(to.path == '/inadimplencia'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_INADIMPLENCIA')){
        router.push('restrito');
      }
    }

    if(to.path == '/campanha'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_CAMPANHA')){
        router.push('restrito');
      }
    }

    if(to.path == '/acompanhamentovenda'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_ACOMPANHAMENTO_VENDA')){
        router.push('restrito');
      }
    }

    if(to.path == '/campanha2'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_CAMPANHA')){
        router.push('restrito');
      }
    }



    if(to.path == '/auditoria'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_AUDITORIA')){
        router.push('restrito');
      }
    }

    if(to.path == '/'){
        router.push('inicio');
      
    }

    if(to.path == '/receita'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_RECEITA')){
        router.push('restrito');
      }
    }

    if(to.path == '/notificacao'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_NOTIFICACAO')){
        router.push('restrito');
      }
    }

    if(to.path == '/financeiro'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_RESUMO')){
        router.push('restrito');
      }
    }

    if(to.path == '/inadimplenciaPerformance'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_INADIMPLENCIA_PERFORMANCE')){
        router.push('restrito');
      }
    }

    if(to.path == '/comissao'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_COMISSAO')){
        router.push('restrito');
      }
    }

    if(to.path == '/funeraria'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_FUNERARIA')){
        router.push('restrito');
      }
    }
    if(to.path == '/comissaoVenda'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_COMISSAO_VENDA')){
        router.push('restrito');
      }
    }

    if(to.path == '/comissaoEntrega'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_COMISSAO_ENTREGAS')){
        router.push('restrito');
      }
    }

    if(to.path == '/comissaoFuneraria'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_COMISSAO_FUNERARIA')){
        router.push('restrito');
      }
    }

    if(to.path == '/acordosPerformance'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_ACORDO_PERFORMANCE')){
        router.push('restrito');
      }
    }


    if(to.path == '/sinistralidade'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_SINISTRALIDADE')){
        router.push('restrito');
      }
    }


    if(to.path == '/teste'){
      if(!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_TESTE')){
        router.push('restrito');
      }
    }

 
}
  
      next()
}),



new Vue({
  router,
  store,
  vuetify,
  i18n,
  components:{ ApexCharts,
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  render: h => h(App),
}).$mount('#app')
